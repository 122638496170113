"use client";
import * as Sentry from "@sentry/nextjs";
import CountryBanPlaceholder from "@whitelabel-engine/features/CountryBanPlaceholder";
import { useEffect } from "react";

export default function GlobalError({
  error,
}: {
  error: Error & { digest?: string };
}) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <CountryBanPlaceholder
      message="Oops, something went wrong."
      logo="/assets/ApeAcceleratorLarge.png"
      logoOpacity="20"
      alt="Project logo"
      backgroundImage="assets/countrybanplaceholder.png"
    />
  );
}
